import Service from '@/helpers/service'

export default {

    get(page, params) {
        return Service().get('integration/sap/sap-connection?page=' + page, {params});
    },
    show(id) {
        return Service().get('integration/sap/sap-connection/show/'+id);
    },
    create(params) {
        return Service().post('integration/sap/sap-connection/create', params);
    },
    update(id, params) {
        return Service().post('integration/sap/sap-connection/update/'+id, params);
    },
    delete(id) {
        return Service().post('integration/sap/sap-connection/delete/'+id);
    },  
    change_default(id) {
        return Service().post('integration/sap/sap-connection/change-default/'+id);
    } 
}